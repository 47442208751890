.login,
.register {
  display: flex;
  min-height: 100vh;
  .left {
    width: 50%;
    max-width: calc(100vw - 490px);
    align-items: center;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    position: relative;
    @media screen and (orientation: portrait) {
      display: none;
    }
    .txt {
      color: #fff;
      position: relative;
      z-index: 2;
      h1 {
        margin-bottom: 38px;
        max-width: 460px;
        font-family: "ProximaNova";
        font-style: normal;
        font-weight: bold;
      }
      p {
        line-height: 2.4rem;
        margin-bottom: 16px;
        max-width: 370px;
        font-family: "Roboto";
        font-style: normal;
        font-weight: normal;
      }
    }
    &::after {
      content: "";
      background-color: rgba($color: #000000, $alpha: 0.5);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .right {
    display: flex;
    align-items: center;
    // justify-content: center;
    height: 100vh;
    overflow: auto;
    flex-direction: column;
    width: 50%;

    position: relative;
    margin: 0 auto;
    @media only screen and (orientation: portrait) {
      width: 100%;
    }
    & > img {
      position: absolute;
      width: 300px;
      &.supDer {
        top: -18px;
        right: 0;
      }
      &.infIzq {
        bottom: 0;
        left: 20px;
      }
    }
    a.backArrow {
      position: absolute;
      top: -91px;
      left: 95px;
      @media only screen and (orientation: portrait) {
        left: 20px;
      }
      img {
        width: 32px;
        height: 32px;
      }
      &:hover {
        opacity: 0.5;
      }
    }
    form {
      position: relative;
      z-index: 2;
      width: 100%;
      padding: 95px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @media only screen and (orientation: portrait) {
        padding: 0 20px;
        max-width: 380px;
      }
      h2 {
        color: #140a4c;
        font-weight: bold;
        display: block;
        width: 100%;
        text-align: left;
        margin-bottom: 40px;
      }
      span {
        display: block;
        width: 100%;
        label {
          color: #55637e;
          display: block;
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          a {
            background: transparent;
            font-size: 1.2rem;
            color: #0cb5c3;
            max-width: fit-content;
            &:hover {
              opacity: 0.5;
            }
          }
        }
        input {
          margin-bottom: 20px;
        }
      }
      a {
        &.linkAuth {
          font-weight: bold;
          font-size: 1.1rem;
          display: block;
          margin: 0 0 35px;
          &:hover {
            opacity: 0.5;
          }
        }
      }
      button {
        margin: 20px 0;
        width: 100%;
        &:hover {
          opacity: 0.8;
        }
      }
    }
    form.sended {
      h2 {
        color: #140a4c;
        font-weight: 700;
        display: block;
        width: 100%;
        text-align: center;
        margin-bottom: 15px;
      }
      p {
        line-height: 2.2rem;
        text-align: center;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: #140a4c;
        margin-bottom: 30px;
      }
    }
  }
}

.pay {
  button {
    &:hover {
      opacity: 0.8;
    }
  }
  .infoPay {
    h2 {
      margin-bottom: 15px;
      color: #140a4c;
    }
    p,
    li {
      display: flex;
      font-size: 16px;
      margin-bottom: 15px;
      span {
        flex: 1;
      }
      b {
        color: #140a4c;
        flex: 1;
      }
      &:last-of-type {
        margin-bottom: 30px;
      }
    }
  }
  li {
    display: flex;
    font-size: 16px;
    margin-bottom: 15px;
    color: #140a4c;
    font-weight: 500;
    span {
      flex: 1;
    }
    b {
      color: #140a4c;
      flex: 1;
    }
    &:last-of-type {
      margin-bottom: 30px;
    }
  }
}

.centerflex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  h2 {
    margin-bottom: 30px;
  }
}

.links {
  display: flex;
  gap: 30px;
  a {
    transition: all 0.6s cubic-bezier(0.16, 1, 0.3, 1);
    &:hover {
      opacity: 0.5;
    }
  }
}
